(function($) {

	// Individual accordion

    $(".m-flexible-accordions__heading a").bind("click",function(e){
        var target = $(this).attr("href");
        $(target).toggleClass("m-flexible-accordions__item--isactive");
        e.preventDefault();
    });

})(jQuery);