(function($) {


	// Toggle menu (mobile)
	$(".m-header__toggle").bind("click",function(){
		$(".m-header__logo").toggleClass("m-header__logo--isactive");
		$(".m-header__menu-mobile").toggleClass("m-header__menu-mobile--isactive");
		$(".m-header__menu-mobile-close").toggleClass("m-header__menu-mobile-close--isactive");
		$(".m-header__toggle").toggleClass("m-header__toggle--isactive");
	});


	// Close menu (mobile)
	$(".closemenu").bind("click",function(e){
		$(".m-header__logo--isactive").removeClass("m-header__logo--isactive");
		$(".m-header__menu-mobile-close--isactive").removeClass("m-header__menu-mobile-close--isactive");
		$(".m-header__menu-mobile").removeClass("m-header__menu-mobile--isactive");
		$(".m-header__toggle--isactive").removeClass("m-header__toggle--isactive");
		e.preventDefault();
	});


	// Sub-menus (mobile)
	$(".m-menu__primary-mobile .menu-item-has-children > a").each(function() {

		$(this).bind("click",function(e){

			// First click opens the menu, second goes to page
			if (!$(this).parent().hasClass("m-menu__primary-mobile-item--opensub")) {
				$(this).parent().addClass("m-menu__primary-mobile-item--opensub");
				e.preventDefault();
			}

		});

	});


	// Open sub-menu (primary)

	$(".m-menu__primary > ul > .menu-item > a").each(function() {

		$(this).bind("focus mouseover",function(e){
			$(".m-menu__primary--opensub").removeClass("m-menu__primary--opensub");

			if($(this).parent().hasClass("menu-item-has-children")) {
				$(this).parent().addClass("m-menu__primary--opensub");
			}

		});

	});


	// Close sub-menu (primary)

	$(".m-header__menu-primary").bind("clickoutside focusoutside mouseoveroutside",function(){
		$(".m-menu__primary--opensub").removeClass("m-menu__primary--opensub");
	});


	// Toggle search form
	$(".m-header__search button").bind("click",function(){

		$(".m-header__search").toggleClass("m-header__search--isactive");

		if($(".m-header__search").hasClass("m-header__search--isactive")) {
			$(".m-header__search input").focus();
		}

	});


})(jQuery);