(function($) {

	// Scrollto link

    $(".scrollto").bind("click",function(e){

		var
			href = $(this).attr( 'href' ),
			html = $('html, body');

		scrollto = $(href).offset().top;

		html.animate({scrollTop: scrollto});

		e.preventDefault();

	});

})(jQuery);