// General cookie functions

function createCookie(name,value,days) {

	var expires = "";

	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		expires = "; expires="+date.toGMTString();
	}

	document.cookie = name+"="+value+expires+"; path=/";

}

function eraseCookie(name) { createCookie(name,"",-1); }

function readCookie(name) {

	var nameEQ = name + "=";
	var ca = document.cookie.split(';');

	for(var i=0;i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') { c = c.substring(1,c.length); }
		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length,c.length);
		}
	}

	return null;

}

(function ($) {

	var cookies_accepted = readCookie('cookies_accepted');

	if (cookies_accepted === null) { jQuery(".m-cookie-notice").removeClass("m-cookie-notice--hidden"); }

	jQuery(".m-cookie-notice__accept").bind("click",function(){
		createCookie('cookies_accepted','yes',365);
		jQuery(".m-cookie-notice").addClass("m-cookie-notice--hidden");
		return false;
	});

})(jQuery);