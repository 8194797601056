(function($) {

	// People modal
	$('.m-team__item a').each(function() {
		$(this).bind("click",function(e){

			var
				href = $(this).attr( 'href' ),
				html = $('html, body');

				scrollto = $(href).offset().top;
				html.animate({scrollTop: scrollto});

			$("#modal-team h2").text($(this).closest(".m-team__item").attr("data-name"));
			$("#modal-team img").attr("alt",$(this).closest(".m-team__item").attr("data-name"));
			$("#modal-team img").attr("src",$(this).closest(".m-team__item").attr("data-image"));
			$("#modal-team-info").html($(this).closest(".m-team__item").attr("data-bio"));
			$("#modal-team-position").text($(this).closest(".m-team__item").attr("data-position"));

			$("#modal-team").addClass("m-modal--isactive");
			$("#modal-team-close").addClass("m-modal__close-bg--isactive");
			$(".m-modal-toggle").focus();

			e.preventDefault();
			e.stopPropagation();

		});
	});

	// Close modal

	$('.m-modal__close-bg').bind("click",function(e){
		$("#modal-team").removeClass("m-modal--isactive");
		$("#modal-team-close").removeClass("m-modal__close-bg--isactive");
		e.preventDefault();
		e.stopPropagation();
	});
	
	$(".m-modal-toggle").bind("click",function(e){
		$("#modal-team").removeClass("m-modal--isactive");
		$("#modal-team-close").removeClass("m-modal__close-bg--isactive");
	});

})(jQuery);