(function ($) {
  var arrow_next =
    '<div class="slick-arrow-container slick-arrow-container-next"><button type="button" class="slick-next">Next</button></div>';
  var arrow_prev =
    '<div class="slick-arrow-container slick-arrow-container-prev"><button type="button" class="slick-prev">Previous</button></div>';

  $(".ctf-tweet-items").slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: true,
    pauseOnHover: false,
    speed: 750,
  });

  $(".m-flexible-logos__slides").slick({
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    nextArrow: arrow_next,
    pauseOnHover: false,
    prevArrow: arrow_prev,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slide: "div",
    slidesToScroll: 1,
    slidesToShow: 6,
    speed: 750,
  });

  $(".m-flexible-quotes__slides").slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: true,
    pauseOnHover: false,
    speed: 750,
  });
})(jQuery);
