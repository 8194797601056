(function ($) {
  $(".m-flexible-join__toggle").bind("click", function (e) {
    $(".m-flexible-join__info--isactive").removeClass(
      "m-flexible-join__info--isactive"
    );
    $(".m-flexible-join__toggle--isactive").removeClass(
      "m-flexible-join__toggle--isactive"
    );
    $(this).addClass("m-flexible-join__toggle--isactive");

    target = $(this).attr("href");
    $(target).toggleClass("m-flexible-join__info--isactive");

    e.preventDefault();
  });
})(jQuery);
